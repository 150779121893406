import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4531454"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "head-main" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "main-box" }
const _hoisted_4 = { class: "main-wrapper" }
const _hoisted_5 = { class: "main-sidebar-l" }
const _hoisted_6 = { class: "main-type" }

import global from "@/config/global.config"
import LeftSearch from './LeftSearch.vue';
import RightInfo from './RightInfo.vue';
import { getCurrentInstance, inject, reactive, toRefs,onBeforeMount,ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { $apiStoreBanner,$apiSaleShop } from "@/api/modules/store";
import { useStore } from "vuex";
import {  A11y,Autoplay,EffectFade,Navigation, Pagination, Scrollbar, } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";
import { ElMessage } from 'element-plus';


export default {
  setup(__props) {

let modules = [ A11y,Autoplay,EffectFade,Navigation, Pagination, Scrollbar]
const store = useStore()
const router = useRouter() ,route =useRoute()
const { proxy: { $msg, $filters: { concatImg } } } = getCurrentInstance();
const state = reactive({
  token: localStorage.getItem("token") || "",
  user_info: localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info')):{},
  companyId :route.query.companyId || route.meta?.info?.id,
  typeList: [],
  bannerList: [],
  dynamicList:'',
  saleShopList:[],
  saleTons:0,
})

let isPc = ref(true)
if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  isPc.value = false
} else {
  isPc.value = true
}

// 商城banner
getStoreBanner()
async function getStoreBanner(){
  const data = await $apiStoreBanner({companyId:state.companyId}) 
  data.status?state.bannerList.push(...data.data):false
}


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Swiper), {
      modules: _unref(modules),
      effect: "fade",
      autoplay: {delay: 2500,disableOnInteraction: false},
      pagination: {clickable: true},
      class: "home_banner",
      style: _normalizeStyle({width:_unref(isPc)?'1903px':'100%'})
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).bannerList, (banner) => {
          return (_openBlock(), _createBlock(_unref(SwiperSlide), {
            key: banner.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _unref(global).cdn(banner.image),
                alt: ""
              }, null, 8, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules", "style"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("li", _hoisted_6, [
            _createVNode(LeftSearch)
          ])
        ]),
        _createVNode(RightInfo)
      ])
    ])
  ]))
}
}

}